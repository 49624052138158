<template>
  <div>
    <a-form layout="horizontal">
      <div class="w-100">
        <div class="row">
          <div class="col-md-3">
            <a-form-item>
              <template #label>
                <a-typography-title :level="5">Выберите филиал</a-typography-title>
              </template>
              <a-select
                :options="stores"
                v-model:value="selectedStore"
                @change="updateStore"
                :option-filter-prop="'label'"
                placeholder="Филиал"
                style="width: 250px"
                size="large"
                show-search
                allow-clear
              />
            </a-form-item>
          </div>
        </div>
      </div>
    </a-form>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="tableChange"
      row-key="id"
    >
      <template #title>
        <div class="pb-1 overflow-hidden">
          <a-typography-title :level="3" class="pull-left">Заказы</a-typography-title>
          <a-button
            class="add-table pull-right ml-3"
            type="primary"
            @click="router.push({ name: 'order' })"
            v-if="['admin', 'cashier', 'brandmanager'].includes(user.role)"
          >
            Создать заказ
          </a-button>
        </div>
      </template>
      <template #number="{ record }">
        {{ record.number }}
      </template>
      <template #user="{ record }">
        {{ formattedPhone(record.user?.phone) }}
      </template>
      <template #cart="{ record }">
        <a-button shape="round" @click="openCart(record)">
          <template #icon>
            <ShoppingCartOutlined />
          </template>
          {{ record.details?.items?.length }}
        </a-button>
      </template>
      <template #price="{ record }">
        {{ formattedBalance(record?.details?.['orderSum']) }}
      </template>
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author">
          ({{ record.author }})
        </span>
      </template>
      <template #status="{ record }">
        <a-tag
          @click="updateColumn(record['uuid'], 'status', 1)"
          v-if="record['status'].toString() === '0'"
          color="default"
          class="cursor-pointer"
        >
          <template #icon>
            <MinusCircleOutlined />
          </template>
          Не оплачен
        </a-tag>
        <a-tag
          v-if="record['status'].toString() === '1'"
          color="default"
          class="cursor-pointer"
        >
          <template #icon>
            <ClockCircleOutlined />
          </template>
          Ждет подтверждение
        </a-tag>
        <a-tag
          v-if="record['status'].toString() === '2'"
          color="cyan"
          class="cursor-pointer"
        >
          <template #icon>
            <ClockCircleOutlined />
          </template>
          Принят
        </a-tag>
        <a-tag
          v-if="record['status'].toString() === '3'"
          color="processing"
          class="cursor-pointer"
        >
          <template #icon>
            <ClockCircleOutlined />
          </template>
          Ожидает
        </a-tag>
        <a-tag
          v-if="record['status'].toString() === '4'"
          color="processing"
          class="cursor-pointer"
        >
          <template #icon>
            <ClockCircleOutlined />
          </template>
          Готовится
        </a-tag>
        <a-tag
          v-if="record['status'].toString() === '5'"
          color="processing"
          class="cursor-pointer"
        >
          <template #icon>
            <ClockCircleOutlined />
          </template>
          Ждет отправки
        </a-tag>
        <a-tag
          v-if="record['status'].toString() === '6'"
          color="processing"
          class="cursor-pointer"
        >
          <template #icon>
            <ClockCircleOutlined />
          </template>
          В пути
        </a-tag>
        <a-tag
          v-if="record['status'].toString() === '7'"
          color="success"
          class="cursor-pointer"
        >
          <template #icon>
            <CheckCircleOutlined />
          </template>
          Завершен (доставлен)
        </a-tag>
        <a-tag
          v-if="record['status'].toString() === '8'"
          color="warning"
          class="cursor-pointer"
        >
          <template #icon>
            <ExclamationCircleOutlined />
          </template>
          Отменен
        </a-tag>
        <a-tag
          v-if="record['status'].toString() === '9'"
          color="error"
          class="cursor-pointer"
        >
          <template #icon>
            <MinusCircleOutlined />
          </template>
          Удален
        </a-tag>
      </template>
      <template #action="{ record }">
        <div class="w-100 text-right">
          <a-tooltip placement="top" class="mx-2">
            <template #title>Редактировать</template>
            <a @click="openCart(record)"><i class="fe fe-edit"></i></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2">
            <template #title>Удалить</template>
            <a @click="deleteModal = true;deleteId = record['uuid']"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 d-flex justify-content-between">
      <p><b>Количество:</b> {{ total }}</p>
      <a-pagination
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
    <a-modal
      :title="`Номер заказа: ${orderNumber}`"
      :visible="cartModal"
      @cancel="closeCart"
    >
      <template #footer>
        <a-button key="back" @click="closeCart">Закрыть</a-button>
      </template>
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Продукт</th>
          <th>Количество</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in orderItems" :key="key">
            <td>
              <div class="order-image" :style="{ backgroundImage: `url(${item?.['imageUrl']})` }">
              </div>
              <span class="order-name">{{ item.name }}</span>
            </td>
            <td><span class="order-count">{{ item.count }}</span></td>
          </tr>
        </tbody>
      </table>
      <a-form-item label="Статус заказа">
        <a-select show-search v-model:value="orderStatus" @change="changeOrderStatus">
          <a-select-option value="0">Не оплачен</a-select-option>
          <a-select-option value="1">Новый (Ждет подтверждение)</a-select-option>
          <a-select-option value="2">Принят</a-select-option>
          <a-select-option value="3">Ожидает</a-select-option>
          <a-select-option value="4">Готовится</a-select-option>
          <a-select-option value="5">Приготовлен (ждет отправки)</a-select-option>
          <a-select-option value="6">В пути</a-select-option>
          <a-select-option value="7">Завершен (доставлен)</a-select-option>
          <a-select-option value="8">Отменен</a-select-option>
          <a-select-option value="9">Удален</a-select-option>
        </a-select>
      </a-form-item>
    </a-modal>
    <a-modal
      :visible="deleteModal"
      @cancel="deleteModal = false;deleteId = ''"
      :footer="null"
    >
      <a-typography-title :level="5">Вы действительно хотите удалить заказ?</a-typography-title>
      <div class="footer overflow-hidden pt-2">
        <a-button @click="deleteOrder" type="primary" class="float-right">Да</a-button>
        <a-button @click="deleteModal = false;deleteId = ''" type="secondary" class="float-right mx-2">Нет</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import {
  ShoppingCartOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue';
import { computed, onMounted, reactive, ref, toRefs, watch } from 'vue';
import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
import apiClient from "@/services/axios";
import { message } from 'ant-design-vue';
import { useStore } from "vuex";
import { mask } from "maska";

let cartModal = ref(false),
  stores = ref([]),
  selectedStore = ref('null'),
  orderId = ref(null),
  orderNumber = ref(null),
  orderStatus = ref(0),
  orderItems = ref([]),
  storeId = ref(0),
  brandId = ref(0),
  categoryName = ref(''),
  dataSource = ref([]),
  loading = ref(false),
  total = ref(0);

const router = useRouter(),
  user = computed(() => useStore().getters['user/user']),
  deleteId = ref(''),
  deleteModal = ref(false),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    name: '',
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'Номер заказа',
      dataIndex: 'number',
      key: 'number',
      width: 150,
      fixed: 'left',
      slots: { customRender: 'number' },
    },
    {
      title: 'Телефон',
      dataIndex: 'user',
      key: 'user',
      width: 200,
      slots: { customRender: 'user' },
    },
    {
      title: 'Корзинка',
      dataIndex: 'cart',
      key: 'cart',
      width: 130,
      slots: { customRender: 'cart' },
    },
    {
      title: 'Сумма заказа',
      dataIndex: 'price',
      key: 'price',
      width: 130,
      slots: { customRender: 'price' },
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      key: 'added_on',
      width: 200,
      slots: { customRender: 'date' },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      slots: { customRender: 'status' },
    },
    {
      key: 'action',
      fixed: 'right',
      width: 60,
      slots: { customRender: 'action' },
    },
  ],
  formattedDatetime = (date) => {
    date = date.replace(/ /g,"T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({ sort: sign + sorter.field })
    }
  },
  openCart = (order) => {
    orderId.value = order.id
    orderNumber.value = order.number
    orderItems.value = order.details.items
    orderStatus.value = order.status
    cartModal.value = true
  },
  closeCart = () => {
    cartModal.value = false
  },
  changeOrderStatus = (value) => {
    updateColumn(orderId.value, 'status', value);
  },
  formattedPhone = (phone) => {
    return (phone) ? mask(phone, '+998(##) ###-##-##') : ''
  },
  formattedBalance = (balance, divide = false) => {
    const formatter = new Intl.NumberFormat("RU", {
      style: "currency",
      currency: "UZS",
    });
    const formattedBalance = divide
      ? formatter.format(balance / 100)
      : formatter.format(balance);
    return formattedBalance.substring(0, formattedBalance.length - 4);
  },
  updateColumn = (id, column, value) => {
    let data = {};
    data[column] = value;
    apiClient.put(`/menu/order-update?order=${id}`, data).then(response => {
      if (response) {
        message.success('Заказ успешно обновлен')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
    });
  },
  deleteOrder = () => {
    return apiClient.delete(`menu/order-delete?order=${deleteId.value}`).then(response => {
      if (response) {
        message.success('Заказ успешно удален')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      deleteModal.value = false;
      deleteId.value = ''
      reload()
    });
  },
  updateStore = (store) => {
    router.replace({
      name: 'orders',
      params: { store }
    })
  },
  getData = async (store, params) => {
    loading.value = true
    if (store === 'null') {
      await apiClient.get('/menu/orders?store=null', { params }).then(({ data }) => {
        store = data.data.stores.find(store => store.value !== 'null')?.value
        selectedStore.value = store
        router.replace({
          name: 'orders',
          params: { store }
        })
      });
    }
    return apiClient.get(`/menu/orders?store=${store}`, { params }).then(({data}) => {
      loading.value = false
      dataSource.value = data.data.items
      stores.value = data.data.stores
      total.value = data.data.total
      storeId.value = data.data.store
      brandId.value = data.data.brand
      categoryName.value = data.data['category_name']
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  reload = () => {
    getData(route.params.store, {
      ...queryParams,
    })
  };

onBeforeRouteUpdate((to, from, next) => {
  getData(to.params.store, {
    ...queryParams,
  })
  next()
})
onMounted(() => {
  getData(route.params.store, {
    ...queryParams,
  })
})

watch(queryParams, () => {
  router.push({
    name: 'orders',
    params: {
      store: route.params.store,
    },
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData(route.params.store, {
    ...route.query,
    ...queryParams,
  })
})

const query = {...toRefs(queryParams)}

</script>
<style>
.order-image {
  margin-right: 10px;
  width: 50px;
  height: 50px;
  float: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
}
.order-name {
  display: block;
  float: left;
  line-height: 50px;
}
.order-count {
  line-height: 50px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
